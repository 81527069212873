
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import membersApi from "@/core/services/MembersApi";
import emailingApi from "@/core/services/EmailingApi";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import Quill from "quill/dist/quill.js";
import moment from "moment";

export default defineComponent({
    name: "CampaignDetails",
    components: { Form, Field },
    data() {
        return {
            tagsKey: 0,
            filtersPayload: {},
            viewing: false,
            currentPage: 1,
            totalPages: 0,
            selectedCampaigns: [] as any,
            participants: [] as any,
            actualFilter: "",
            actualCol: "",
            campaign: {} as any,
            loading: true,
            title: "" as any,
            perm_pos: "",
            activeFilters: [] as any,
            reachedMembers: "",
            payload: {} as any,
            activeName: "1",
            segments: [] as any,
            step1Valid: false,
            step2Valid: false,
            step3Valid: false,
            frEditor: "" as any,
            enEditor: "" as any,
            canUpdate: false,
            dialogVisible: false,
            campaignTemplate: "",
            newCampaign: {
                segment: {
                    id: "",
                    label: "",
                    reached: "",
                },
                delivery_date: "",
                committee: "",
                email: "",
                signature: "",
                subject: "",
                template: "",
                title_fr: "",
                title_en: "",
                content_fr: "",
                content_en: "",
            },
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const editorFr = "wysiwyg-fr";
        const editorEn = "wysiwyg-en";

        // init editor
        const optionsFr = {
            modules: {
                toolbar: {
                    container: "#toolbarFr",
                },
            },
            theme: "snow",
        };
        const optionsEn = {
            modules: {
                toolbar: {
                    container: "#toolbarEn",
                },
            },
            theme: "snow",
        };

        const campaignStep1Schema = object().shape({
            segment: string().required().label("Segment"),
            delivery_date: string().required().label("Delivery date"),
        });

        const campaignStep2Schema = object().shape({
            // committee: string().required().label("Committe"),
            // email: string().required().label("Email"),
            // signature: string().required().label("Signature"),
            subject: string().max(100).required().label("Subject"),
        });

        const campaignStep3Schema = object().shape({
            // template: string().required().label("Template"),
            title_fr: string().max(40).required().label("French title"),
            title_en: string().max(40).required().label("English title"),
            // content_fr: string().required().label("French content"),
            // content_en: string().required().label("English content"),
        });

        const backToList = () => {
            router.push({ name: "campaigns" });
        };

        return {
            backToList,
            campaignStep1Schema,
            campaignStep2Schema,
            campaignStep3Schema,
            route,
            router,
            editorFr,
            editorEn,
            optionsFr,
            optionsEn,
        };
    },
    mounted() {
        this.title = document.querySelector("#title-breadcrumbs h1") as HTMLElement;
        if (this.title) this.title.style.cssText = "display: none!important;";

        if (this.route.params.campaign_id) {
            this.viewing = true;
            this.getCampaign(this.$route.params.campaign_id);
            setCurrentPageBreadcrumbs("Campaign #" + this.route.params.campaign_id, [
                {
                    link: "/emailing/campaigns",
                    label: "Campaigns",
                },
            ]);
        } else {
            this.loading = false;
            setCurrentPageBreadcrumbs("New Campaign", [
                {
                    link: "/emailing/campaigns",
                    label: "Campaigns",
                },
            ]);
        }

        this.frEditor = new Quill("#" + this.editorFr, this.optionsFr);
        this.enEditor = new Quill("#" + this.editorEn, this.optionsEn);

        this.frEditor.on("text-change", () => {
            var delta = this.frEditor.root.innerHTML;
            this.newCampaign.content_fr = delta;
        });
        this.enEditor.on("text-change", () => {
            var delta = this.enEditor.root.innerHTML;
            this.newCampaign.content_en = delta;
        });
        this.getFields();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    beforeUnmount() {
        this.title.style.cssText = "display: flex !important;";
    },
    methods: {
        validateStep(step_nb: number) {
            switch (step_nb) {
                case 1:
                    this.step1Valid = true;
                    ElMessage({
                        type: "success",
                        message: "Step 1 validated.",
                    });
                    break;
                case 2:
                    this.step2Valid = true;
                    ElMessage({
                        type: "success",
                        message: "Step 2 validated.",
                    });
                    break;
                case 3:
                    if (this.newCampaign.content_fr && this.newCampaign.content_en) {
                        this.step3Valid = true;
                        ElMessage({
                            type: "success",
                            message: "Step 3 validated.",
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "Please fill in the content of the email in both languages.",
                        });
                    }
                    break;
            }
        },
        sendCampaign() {
            var payload = {};

            const date = moment(this.newCampaign.delivery_date, "DD/MM/YYYY");

            payload = {
                title_fr: this.newCampaign.title_fr,
                title_en: this.newCampaign.title_en,
                subject: this.newCampaign.subject,
                delivery_date: date.format("YYYY-MM-DDTHH:mm"),
                segment: this.newCampaign.segment.id,
                content_en: this.newCampaign.content_en,
                content_fr: this.newCampaign.content_fr,
            };

            if (!this.viewing && !this.canUpdate) {
                emailingApi.addCampaign(payload).then((res) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Campaign added",
                        });
                        this.router.push({ name: "campaigns" });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred",
                        });
                    }
                });
            } else if (this.canUpdate) {
                emailingApi.updateCampaign(this.route.params.campaign_id, payload).then((res) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Campaign updated",
                        });
                        emailingApi.getCampaign(this.route.params.campaign_id).then((res) => {
                            if (res.success) this.campaignTemplate = res.data.template;
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred",
                        });
                    }
                });
            }
        },
        getCampaign(id: any) {
            this.loading = true;

            emailingApi.getCampaign(id).then((response) => {
                this.newCampaign.segment.id = response.data.campaign.segment;
                emailingApi.getSegment(response.data.campaign.segment).then((res) => {
                    this.newCampaign.segment.label = res.data.segment.title;
                    this.newCampaign.segment.reached = res.data.segment.reached;
                });
                this.newCampaign.delivery_date = new Date(response.data.campaign.delivery_date).toLocaleDateString();
                this.newCampaign.subject = response.data.campaign.subject;
                this.newCampaign.title_fr = response.data.campaign.title_fr ? response.data.campaign.title_fr : "";
                this.newCampaign.title_en = response.data.campaign.title_en ? response.data.campaign.title_en : "";
                this.frEditor.container.firstChild.innerHTML = response.data.campaign.content_fr;
                this.enEditor.container.firstChild.innerHTML = response.data.campaign.content_en;
                this.campaignTemplate = response.data.template;
                this.step1Valid = this.step2Valid = this.step3Valid = true;

                if (response.data.campaign.status === "DR") {
                    this.canUpdate = true;
                    this.viewing = false;
                }
                this.loading = false;
            });
        },
        getFields() {
            // Get segments
            emailingApi.listSegments({ p: 1, presult: 9999 }).then((res: any) => {
                this.segments = res.data.segment_list;
            });
        },
    },
});
